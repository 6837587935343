<template>
  <div class="signature_box" id="homeEle">
    <van-loading
      class="loading_box"
      :color="themecolors"
      :text-color="themecolors"
      v-if="isHttpData"
    />
    <div v-else class="form_main_content_box">
      <!-- kv -->
      <div class="kv_box">
        <img v-if="sign_kv" :src="sign_kv" alt="" srcset="" />
      </div>
      <!-- 内容 -->
      <div class="content_box">
        <div class="signature_content" v-if="!showSignature">
          <div class="signature_title">线下签到</div>
          <el-input v-model="input" placeholder="请输入手机号/姓名"></el-input>
          <div class="signature_btn" @click="getSignatureClick">签 到</div>
        </div>
        <div class="signatre_success" v-else>
          <div class="success_box">
            <img src="@/assets/successful.svg" alt="" srcset="" />
            <div class="success_tip">恭喜您签到成功!</div>
            <div class="expand_key_box">
              <div
                class="expand_item"
                v-for="item in expandArray"
              >
                {{item.title}}：
                <span class="number_value">{{ item.value }}</span>
              </div>
            </div>
            <div class="tips_txt">温馨提示: 为方便指引，建议截图保存查阅</div>
          </div>
        </div>
      </div>
      <div class="return_btn">
        <div class="go_home" @click="gohome">
          <img src="@/assets/icon/home.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/loading/loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isHttpData: true, //页面加载动画
      showSignature: false,
      input: "",
      sign_kv: "",
      tableNumber: '11',
      expandArray: []
    };
  },
  computed: {
    ...mapState(["themecolors"]),
  },
  mounted() {
    if (localStorage.getItem("site_status") == "true") {
      this.showSignature = true;
      this.input = localStorage.getItem('signatrue_input')
      this.getSigSubsidiaryInfo()
    } else {
      this.showSignature = false;
    }
    this.getConfigDetail()
    document.title = "线下签到";
    const homeEle = document.getElementById('homeEle');
    homeEle.removeAttribute('style');
  },
  methods: {
    // 获取签到配置详情
    getConfigDetail() {
      this.$store.dispatch('getSignSubsidiaryImage', {
        site_id: this.$route.query.site_id
      }).then(res => {
        if (res.data.code == 200) {
          this.sign_kv = res.data.data.img || ''
        }
      }).finally(() => {
        this.isHttpData = false
      })
    },
    // 首页跳转
    gohome() {
      window.location.href = `/event/${this.$route.query.site_url}`;
    },
    // 签到
    getSignatureClick() {
      //   const isNumber = /^\d+$/.test(this.input);
      if (this.input == "") {
        return this.$toast("请输入签名");
      }
      //   else {
      //     if (isNumber && this.input.length != 11) {
      //       return this.$toast("请输入正确的手机号");
      //     }
      //   }
      this.$store
        .dispatch("user_sign", {
          key: this.input,
          site_id: this.$route.query.site_id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.showSignature = true;
            localStorage.setItem("site_status", true);
            localStorage.setItem('signatrue_input', this.input)
            this.getSigSubsidiaryInfo()
          } else {
            this.$toast(res.data.message);
          }
        }); 
    },
    // 获取签到附属信息
    getSigSubsidiaryInfo() {
      this.$store.dispatch('getSignSubsidiaryInfo', {
        site_id: this.$route.query.site_id,
        key: this.input
      }).then(res => {
        if (res.data.code == 200) {
          this.expandArray = res.data.data || []
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.signature_box {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f7f8fa;
}
.loading_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form_main_content_box {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.signature_title {
  width: 100%;
  margin: 0 auto;
  font-size: 0.2rem;
  text-align: center;
  margin-bottom: 50px;
}
.kv_box {
  width: 100%;
  min-height: 1.2rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.number_value {
  color: #00ccb0;
}
.content_box {
  z-index: 1;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: 94%;
  box-shadow: 0 3px 10px #ededed;
  background: #fff;
  border-radius: 6px;
  padding: 50px 20px 50px 20px;
}
.signature_content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/deep/.el-input {
  width: 92%;
}
/deep/.el-input__inner:focus {
  border-color: #00ccb0;
}
.signature_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  padding: 5px;
  font-size: 16px;
  background-color: #00ccb0;
  color: #fff;
  width: 92%;
  border-radius: 8px;
  height: 40px;
  line-height: 32px;
}
.signatre_success {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  .success_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 200px;
    }
  }
  .expand_key_box {
    margin-top: 15px;
    width: 100%;
  }
  .expand_item {
    width: 100%;
    font-size: 18px;
    margin-bottom: 6px;
    word-break: break-all;
  }
}
.tips_txt {
  font-size: 15px;
  margin-top: 15px;
}
.success_tip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: nowrap;
  margin-top: 20px;
  font-size: 0.18rem;
  color: #333;
  font-weight: 700;
}
.return_btn {
  position: fixed;
  bottom: 18%;
  right: 3%;
  z-index: 6;
  .go_home {
    width: 0.35rem;
    height: 0.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    background-color: #00ccb0;
    img {
      width: 0.18rem;
    }
  }
}
</style>
